<script setup lang="ts">
import { Breadcrumbs } from '@upa/design-system';
const { breadcrumbs } = useBreadcrumbs();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { push } = useRouter();
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    breadcrumbTitle?: string;
    breadcrumbLink?: string;
  }>(),
  {
    breadcrumbTitle: '',
    breadcrumbLink: '',
  },
);

// const home =  {
//   'name': t('breadcrumbs.homePath'),
//   'path': t('breadcrumbs.homeLink')
// };

const breadcrumbLink = {
  name: props.breadcrumbTitle,
  path: formatLink('/' + props.breadcrumbLink) as string,
};

const pushRoute = (path: string) => {
  push(path);
};
</script>
<template>
  <Breadcrumbs
    :external-link="$t('breadcrumbs.homeLink')"
    :external-name="$t('breadcrumbs.homePath')"
    :breadcrumbs="
      breadcrumbs ? [breadcrumbLink, ...breadcrumbs] : [breadcrumbLink]
    "
    @click="pushRoute"
  />
</template>
